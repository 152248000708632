import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useAnalytics() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    initialized && pageview();
  }, [location.pathname, initialized]);

  return {
    initialize,
  };

  function initialize() {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    }
    setInitialized(true);
  }

  function pageview() {
    const url = `${window.location.origin}${window.location.pathname}`;
    // Analytics
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.pageview(url);
    }
  }
}
