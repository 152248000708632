import React, { lazy, Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollToTop';
import { Loader } from './components/Loader';
import { useAnalytics } from './hooks/useAnalytics';
// Pages
const Leafee = lazy(() => import('./pages/leafee'));
const Aik = lazy(() => import('./pages/aik'));
const Psupo = lazy(() => import('./pages/psupo'));

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Suspense fallback={<Loader className="min-h-screen text-emerald-500" />}>
          <Router>
            <ScrollToTop />
            <Routing />
          </Router>
        </Suspense>
      </div>
    </HelmetProvider>
  );
}

function Routing() {
  const { initialize } = useAnalytics();
  const location = useLocation();
  // eslint-disable-next-line
  useEffect(() => initialize(), []);
  return (
    <Routes>
      <Route path="/aik-sense/*" element={<Aik />} />
      <Route path="/psupo/*" element={<Psupo />} />
      <Route path="/*" element={<Leafee />} />
      <Route path="*" element={<Navigate to={{ ...location, pathname: `/` }} />} />
    </Routes>
  );
}

export default App;
